// Styles
import '../scss/main.scss';

// Scripts
import { offer } from '@shop/frontend/dist/desktop/order-full-form';
import './custom/social-proof.js';

jQuery(function () {
  offer.setUsingBilling(true);
  offer.getState(function (data) {
    const currency = offer.getCurrency();
    const expeditedPrice = currency + data.availableShippings[0].fullprice + ' AUD';
    $('#expedited-price').text(expeditedPrice);

    const customer_ip = data.customer_ip;
    const talkJsPopup = $('.__talkjs_popup');
    Talk.ready.then(function () {
      // Generate string for user id;
      function uuidv4() {
        var crypto = window.crypto;
        var randomByte = crypto
          ? function () {
              return crypto.getRandomValues(new Uint8Array(1))[0];
            }
          : function () {
              return Math.floor(Math.random() * 255);
            };
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (c) {
          return (c ^ (randomByte() & (15 >> (c / 4)))).toString(16);
        });
      }

      // Check or set user id;
      var chatParams = {};
      var storageParams = localStorage.getItem('chatParams');

      if (storageParams) {
        var storageChatParams = JSON.parse(storageParams);
        chatParams = {
          customerId: storageChatParams.customerId,
          sellerId: storageChatParams.sellerId,
        };
      } else {
        chatParams = {
          customerId: uuidv4(),
          sellerId: uuidv4(),
        };
        try {
          localStorage.setItem('chatParams', JSON.stringify(chatParams));
        } catch (e) {
          console.error(e);
        }
      }

      // Create user;

      function generateRandomId() {
        // Генерируем случайный 8-значный идентификатор
        return Math.random().toString(36).substring(2, 10);
      }

      const userId = generateRandomId(); // генерирует случайный идентификатор
      const me = new Talk.User({
        id: userId,
        name: 'Guest',
        photoUrl: 'https://talkjs.com/images/default-avatar.png',
        role: 'customer',
        custom: {
          key: 'ae48dba7414460b39ef7a7b51ad8c38f',
          language: window.navigator.language,
          ua: window.navigator.userAgent,
          customer_ip: customer_ip,
        },
      });

      var session = new Talk.Session({
        appId: 'ZQDGLbWj',
        me: me,
      });

      var monitor = new Talk.User({
        id: 'cb00c91a-937d-4640-b7dd-ad3710e612aa',
        name: 'Chat',
      });

      var other = new Talk.User({
        id: chatParams.sellerId,
        name: 'Support',
        photoUrl: '/static/common/shared/avatar.png',
        welcomeMessage: 'Hey, how can I help?',
        role: 'agent',
      });

      // Get Or Create Conversation;
      var conversation = session.getOrCreateConversation(Talk.oneOnOneId(me, other));
      conversation.setParticipant(me);
      conversation.setParticipant(other);
      conversation.setParticipant(monitor);
      conversation.setAttributes({
        subject: 'Live Chat',
        photoUrl: location.origin + '/static/common/shared/customer.png',
      });

      // Mount popup;
      var popup = session.createPopup(conversation, {
        keepOpen: false,
        chatTitleMode: 'subject',
        chatSubtitleMode: null,
      });
      popup.mount({
        show: false,
      });

      // Fixed chatbox in mobile devices
      if ($(window).width() <= 420) {
        talkJsPopup.css('position', 'fixed');
      }

      $('.open-livechat').click(function (e) {
        e.preventDefault();
        popup.show();
      });
    });
  });

  $('select')
    .on('change', function () {
      const $select = $(this);
      if ($select.val() == '') {
        $select.css('color', '#676873');
      } else {
        $select.css('color', '#1C1C28');
      }
    })
    .change();
});
