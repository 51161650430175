const customerFirst = [
  'Liam',
  'Emma',
  'Noah',
  'Olivia',
  'William',
  'Ava',
  'James',
  'Isabella',
  'Logan',
  'Sophia',
  'Benjamin',
  'Mia',
  'Mason',
  'Charlotte',
  'Elijah',
  'Amelia',
  'Oliver',
  'Evelyn',
  'Jacob',
  'Abigail',
  'Lucas',
  'Harper',
  'Michael',
  'Emily',
  'Alexander',
  'Elizabeth',
  'Ethan',
  'Avery',
  'Daniel',
  'Sofia',
  'Matthew',
  'Ella',
  'Aiden',
  'Madison',
  'Henry',
  'Scarlett',
  'Joseph',
  'Victoria',
  'Jackson',
  'Aria',
  'Samuel',
  'Grace',
  'Sebastian',
  'Chloe',
  'David',
  'Camila',
  'Carter',
  'Penelope',
  'Wyatt',
  'Riley',
  'Jayden',
  'Layla',
  'John',
  'Lillian',
  'Owen',
  'Nora',
  'Dylan',
  'Zoey',
  'Luke',
  'Mila',
  'Gabriel',
  'Aubrey',
  'Anthony',
  'Hannah',
  'Isaac',
  'Lily',
  'Grayson',
  'Addison',
  'Jack',
  'Eleanor',
  'Julian',
  'Natalie',
  'Levi',
  'Luna',
  'Christopher',
  'Savannah',
  'Joshua',
  'Brooklyn',
  'Andrew',
  'Leah',
  'Lincoln',
  'Zoe',
  'Mateo',
  'Stella',
  'Ryan',
  'Hazel',
  'Jaxon',
  'Ellie',
  'Nathan',
  'Paisley',
  'Aaron',
  'Audrey',
  'Isaiah',
  'Skylar',
  'Thomas',
  'Violet',
  'Charles',
  'Claire',
  'Caleb',
  'Bella',
  'Josiah',
  'Aurora',
  'Christian',
  'Lucy',
  'Hunter',
  'Anna',
  'Eli',
  'Samantha',
  'Jonathan',
  'Caroline',
  'Connor',
  'Genesis',
  'Landon',
  'Aaliyah',
  'Adrian',
  'Kennedy',
  'Asher',
  'Kinsley',
  'Cameron',
  'Allison',
  'Leo',
  'Maya',
  'Theodore',
  'Sarah',
  'Jeremiah',
  'Madelyn',
  'Hudson',
  'Adeline',
  'Robert',
  'Alexa',
  'Easton',
  'Ariana',
  'Nolan',
  'Elena',
  'Nicholas',
  'Gabriella',
  'Ezra',
  'Naomi',
  'Colton',
  'Alice',
  'Angel',
  'Sadie',
  'Brayden',
  'Hailey',
  'Jordan',
  'Eva',
  'Dominic',
  'Emilia',
  'Austin',
  'Autumn',
  'Ian',
  'Quinn',
  'Adam',
  'Nevaeh',
  'Elias',
  'Piper',
  'Jaxson',
  'Ruby',
  'Greyson',
  'Serenity',
  'Jose',
  'Willow',
  'Ezekiel',
  'Everly',
  'Carson',
  'Cora',
  'Evan',
  'Kaylee',
  'Maverick',
  'Lydia',
  'Bryson',
  'Aubree',
  'Jace',
  'Arianna',
  'Cooper',
  'Eliana',
  'Xavier',
  'Peyton',
  'Parker',
  'Melanie',
  'Roman',
  'Gianna',
  'Jason',
  'Isabelle',
  'Santiago',
  'Julia',
  'Chase',
  'Valentina',
  'Sawyer',
  'Nova',
  'Gavin',
  'Clara',
  'Leonardo',
  'Vivian',
  'Kayden',
  'Reagan',
  'Ayden',
  'Mackenzie',
  'Jameson',
  'Madeline',
  'Noah',
  'William',
  'James',
  'Logan',
  'Benjamin',
  'Mason',
  'Elijah',
  'Oliver',
  'Jacob',
  'Lucas',
  'Michael',
  'Alexander',
  'Ethan',
  'Daniel',
  'Matthew',
  'Aiden',
  'Henry',
  'Joseph',
  'Jackson',
  'Samuel',
  'Sebastian',
  'David',
  'Carter',
  'Wyatt',
  'Jayden',
  'John',
  'Owen',
  'Dylan',
  'Luke',
  'Gabriel',
  'Anthony',
  'Isaac',
  'Grayson',
  'Jack',
  'Julian',
  'Levi',
  'Christopher',
  'Joshua',
  'Andrew',
  'Lincoln',
  'Mateo',
  'Ryan',
  'Jaxon',
  'Nathan',
  'Aaron',
  'Isaiah',
  'Thomas',
  'Charles',
  'Caleb',
  'Josiah',
  'Christian',
  'Hunter',
  'Eli',
  'Jonathan',
  'Connor',
  'Landon',
  'Adrian',
  'Asher',
  'Cameron',
  'Leo',
  'Theodore',
  'Jeremiah',
  'Hudson',
  'Robert',
  'Easton',
  'Nolan',
  'Nicholas',
  'Ezra',
  'Colton',
  'Angel',
  'Brayden',
  'Jordan',
  'Dominic',
  'Austin',
  'Ian',
  'Adam',
  'Elias',
  'Jaxson',
  'Greyson',
  'Jose',
  'Ezekiel',
  'Carson',
  'Evan',
  'Maverick',
  'Bryson',
  'Jace',
  'Cooper',
  'Xavier',
  'Parker',
  'Roman',
  'Jason',
  'Santiago',
  'Chase',
  'Sawyer',
  'Gavin',
  'Leonardo',
  'Kayden',
  'Ayden',
  'Jameson',
  'Kevin',
  'Bentley',
  'Zachary',
  'Everett',
  'Axel',
  'Tyler',
  'Micah',
  'Vincent',
  'Weston',
  'Miles',
  'Wesley',
  'Nathaniel',
  'Harrison',
  'Brandon',
  'Cole',
  'Declan',
  'Luis',
  'Braxton',
  'Damian',
  'Silas',
  'Tristan',
  'Ryder',
  'Bennett',
  'George',
  'Emmett',
  'Justin',
  'Kai',
  'Max',
  'Diego',
  'Luca',
  'Ryker',
  'Carlos',
  'Maxwell',
  'Kingston',
  'Ivan',
  'Maddox',
  'Juan',
  'Ashton',
  'Jayce',
  'Rowan',
  'Kaiden',
  'Giovanni',
  'Eric',
  'Jesus',
  'Calvin',
  'Abel',
  'King',
  'Camden',
  'Amir',
  'Blake',
  'Alex',
  'Brody',
  'Malachi',
  'Emmanuel',
  'Jonah',
  'Beau',
  'Jude',
  'Antonio',
  'Alan',
  'Elliott',
  'Elliot',
  'Waylon',
  'Xander',
  'Timothy',
  'Victor',
  'Bryce',
  'Finn',
  'Brantley',
  'Edward',
  'Abraham',
  'Patrick',
];
const customerLast = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
const customerStates = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
const customerQuantities = ['2', '3', '5'];
function updateSocial() {
  let rCustomerFirst = customerFirst[Math.floor(Math.random() * customerFirst.length)];
  let rCustomerLast = customerLast[Math.floor(Math.random() * customerLast.length)];
  let rStates = customerStates[Math.floor(Math.random() * customerStates.length)];
  let rQuantities = customerQuantities[Math.floor(Math.random() * customerQuantities.length)];
  let rAgo = Math.floor(Math.random() * 21) + 1;

  $('#notify-customer').html(rCustomerFirst + ' ' + rCustomerLast);
  $('#notify-state').html(rStates);
  $('#notify-quantity').html(rQuantities);
  $('#notify-ago').html(rAgo + ' minutes ago');
}

setInterval(function () {
  let divID = $('.custom-social-proof');
  if (divID.is(':hidden')) {
    $('.custom-social-proof').stop().slideToggle('slow');
    updateSocial();
  } else {
    $(divID).stop().slideToggle('slow');
  }
}, 7000);

$('.custom-close').click(function () {
  $('.custom-social-proof').stop().slideToggle('slow');
});
